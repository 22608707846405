import React from 'react';

const SplitSection = ({
  id, className, primarySlot, secondarySlot, reverseOrder
}) => (
  <section id={id} className={`${className}`}>
    <div className="container mx-auto px-12 sm:px-16 flex flex-col lg:flex-row">
      <div className="lg:w-1/2">{primarySlot}</div>
      <div
        className={`mt-10 lg:mt-0 lg:w-1/2 flex justify-center lg:justify-start ${reverseOrder && `order-last lg:order-first`}`}
      >
        {secondarySlot}
      </div>
    </div>
  </section>
);

export default SplitSection;
