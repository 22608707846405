import React from 'react';

const CircleNumber = ({className, children}) => (
  <div
    className={`
      ${className}
      m-auto
      h-16
      w-16
      flex
      items-center
      justify-center
      text-lg
      rounded-full
    `}
  >
    {children}
  </div>
);

export default CircleNumber;
