import React, {useState, useEffect} from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {Radio, RadioGroup, FormControlLabel} from '@mui/material';
import Button from './ui/Button';

const SuccessMessage = 'Thank you for subscribing!';
const InvalidMessage = 'The email you entered is not valid.';
const TimeoutMessage =
  'Looks like your browser is blocking this. Try to disable the tracker and resubmit or use Chrome browser.';

const EMAIL_API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.GATSBY_API_URL
    : process.env.API_URL;

const customRadioStyle = {
  width: 18,
  height: 18,
  borderRadius: '50%',
  marginLeft: 2,
  backgroundColor: '#fff',
  border: '1.5px solid #555555',
};

const CustomRadio = ({...props}) => {
  return (
    <Radio
      icon={<span style={customRadioStyle} />}
      {...props}
      sx={{
        '&.Mui-checked': {
          color: '#A8E8B5',
        },
      }}
    />
  );
};

const CustomFormControlLabel = ({value, label}) => {
  return (
    <FormControlLabel
      value={value}
      control={<CustomRadio />}
      label={<span className="font-body">{label}</span>}
    />
  );
};

const Signup = () => {
  const [email, setEmail] = useState('');
  const [type, setType] = useState(null);
  const [message, setMessage] = useState('');

  // useEffect(() => {
  //   console.log('new email', email);
  // });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage(''); // Reset status message to make it more user friendly
    // console.log('submitting', email, type);

    try {
      const result = await addToMailchimp(email, {
        CTYPE: type,
      });

      if (result.result === 'success') {
        const data = {
          type: 'mailchimp-subscriber',
          user: {
            subscriberType: type,
            email: email,
          },
        };

        const response = await fetch(EMAIL_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          mode: 'no-cors',
          body: JSON.stringify(data),
        });

        setMessage(SuccessMessage);
      } else if (
        result.result === 'error' &&
        result.msg.includes('already subscribed')
      )
        setMessage(`${email} is already subscribed.`);
      else if (result.result === 'error' && result.msg.includes('not valid'))
        setMessage(InvalidMessage);
      else if (result.result === 'error')
        setMessage('Could not submit. Please try again.');

      console.log(result);
    } catch (e) {
      // console.log(e);
      if (e.message === 'Timeout') setMessage(TimeoutMessage);
      // Should not happen
      else setMessage('Could not submit. Please try again.');
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  const handleInvalidEmail = (event) => {
    event.target.setCustomValidity('Please enter an email address.');
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8 md:mt-8 font-body">
      <div className="mb-3 text-left">
        <RadioGroup name="subscriber_type" onChange={handleTypeChange}>
          <CustomFormControlLabel
            value="restaurant"
            label="Business seeking more information"
          />
          <CustomFormControlLabel
            value="customer"
            label="Customer looking to try it out"
          />
        </RadioGroup>
      </div>

      {/* for catching bots */}
      <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
        <input
          type="text"
          name="b_141bdc7edafb7934f906fb61e_cf3f2f557b"
          tabIndex="-1"
          defaultValue=""
        />
      </div>
      <div className="text-center lg:text-left">
        <input
          className="w-3/5 py-3 px-1"
          onChange={handleEmailChange}
          type="email"
          name="email"
          placeholder="Your Email"
          required
          onInvalid={handleInvalidEmail}
        />
        <Button type="submit" size="lg">
          Learn More
        </Button>
        <div className="mt-3" style={{color: '#E58450'}}>
          {message}
        </div>
      </div>
    </form>
  );
};

export default Signup;
