import React, {useState} from 'react';
import {TextField, Box} from '@mui/material';
import Button from './ui/Button'

const EMAIL_API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.GATSBY_API_URL
    : process.env.API_URL;

const ContactForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log('Form submitted:', {firstName, lastName, email, message});

    try {
      const data = {
        type: 'contact-us',
        firstName,
        lastName,
        email,
        message,
      };

      const response = await fetch(EMAIL_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'no-cors',
        body: JSON.stringify(data),
      });

      setError("Message successfully sent. We'll be in touch shortly.");

      // Reset state
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
    } catch (e) {
      setError('Could not submit. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '500px',
          margin: 'auto',
        }}
      >
        <Box sx={{display: 'flex', gap: '20px'}}>
          <TextField
            label="First Name"
            variant="outlined"
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{flex: 1}}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{flex: 1}}
          />
        </Box>
        <TextField
          required
          label="Email Address"
          type="email"
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Message"
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button type="submit" variant="contained" className="font-header text-lg">
          Submit
        </Button>
        <div className="mt-3" style={{color: '#E58450'}}>
          {error}
        </div>
      </Box>
    </form>
  );
};

export default ContactForm;
